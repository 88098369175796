import api from './apiService';

export const getProducts = async () => {
  try {
    const response = await api.get('/api/products');
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const addProduct = async (productData) => {
  try {
    const response = await api.post('/api/products', productData);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const updateProduct = async (id, productData) => {
  try {
    const response = await api.put(`/api/products/${id}`, productData);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const deleteProduct = async (id) => {
  try {
    await api.delete(`/api/products/${id}`);
  } catch (error) {
    handleApiError(error);
  }
};

const handleApiError = (error) => {
  console.error("API Error:", error);
  if (error.response) {
    // Server responded with a status other than 2xx
    console.error("Server Response:", error.response.data);
    throw new Error(error.response.data.message || 'An error occurred while processing the request.');
  } else if (error.request) {
    // Request was made but no response was received
    console.error("No response received:", error.request);
    throw new Error('No response received from the server.');
  } else {
    // Something else caused the error
    console.error("Error:", error.message);
    throw new Error(error.message);
  }
};