// src/components/ProductVendorPage.js
import React from 'react';
import ProductTable from './ProductTable';
import VendorTable from './VendorTable';
import '../assets/styles/Table.css'; // Import the CSS file for styling

const ProductVendorPage = () => {
  return (
    <div className="product-vendor-page">
      <h1>Product and Vendor Management</h1>
      <div className="tables-container">
        <div className="table-section">
          <h2>Products</h2>
          <ProductTable />
        </div>
        <div className="table-section">
          <h2>Vendors</h2>
          <VendorTable />
        </div>
      </div>
    </div>
  );
};

export default ProductVendorPage;