import api from './apiService';

export const getVendors = async () => {
  const response = await api.get('/api/vendors');
  return response.data;
};

export const addVendor = async (vendorData) => {
  const response = await api.post('/api/vendors', vendorData);
  return response.data;
};

export const updateVendor = async (id, vendorData) => {
  const response = await api.put(`/api/vendors/${id}`, vendorData);
  return response.data;
};

export const deleteVendor = async (id) => {
  await api.delete(`/api/vendors/${id}`);
};