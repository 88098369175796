// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
import ManagementPage from './components/ManagementPage';
import VendorTable from './components/VendorTable';
import ProductTable from './components/ProductTable';
import ProductVendorPage from './components/ProductVendorPage'; // Import ProductVendorPage
import Header from './components/Header';
import Footer from './components/Footer';
import Menu from './components/Menu';
import { withAuthenticator, Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css'; // Import Amplify UI styles
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Header className="header" />
        <div className="content">
          <Menu />
          <div className="main-content">
            <Routes>
              <Route exact path="/" element={<HomePage />} />
              <Route path="/managementPage" element={<ManagementPage />} />
              <Route path="/vendors" element={<VendorTable />} />
              <Route path="/products" element={<ProductTable />} />
              <Route path="/product-vendor" element={<ProductVendorPage />} /> {/* Add ProductVendorPage route */}
            </Routes>
          </div>
        </div>
        <Footer className="footer" />
        <Authenticator>
          {({ signOut, user }) => (
            <div>
              <button onClick={signOut}>Sign out</button>
            </div>
          )}
        </Authenticator>
      </div>
    </Router>
  );
}

export default withAuthenticator(App, {
  socialProviders: ['google']
});