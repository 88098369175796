import api from './apiService';

export const getCategories = async () => {
  const response = await api.get('/api/categories');
  return response.data;
};

export const addCategory = async (categoryData) => {
  const response = await api.post('/api/categories', categoryData);
  return response.data;
};

export const updateCategory = async (id, categoryData) => {
  const response = await api.put(`/api/categories/${id}`, categoryData);
  return response.data;
};

export const deleteCategory = async (id) => {
  await api.delete(`/api/categories/${id}`);
};