import React, { useState, useEffect } from 'react';
import { getVendors, addVendor, updateVendor, deleteVendor } from '../services/vendorService';
import '../assets/styles/Table.css'; // Import the CSS file for styling

const VendorTable = () => {
  const [vendors, setVendors] = useState([]);
  const [form, setForm] = useState({ id: null, vendorname: '', contactinfo: '' });
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const fetchVendors = async () => {
      try {
        const vendors = await getVendors();
        setVendors(vendors);
      } catch (error) {
        console.error('Error fetching vendors:', error);
      }
    };

    fetchVendors();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isEditing) {
        await updateVendor(form.id, form.contactinfo);
      } else {
        await addVendor(form.vendorname, form.contactinfo);
      }
      const vendors = await getVendors();
      setVendors(vendors);
      setForm({ id: null, vendorname: '', contactinfo: '' });
      setIsEditing(false);
    } catch (error) {
      console.error('Error saving vendor:', error);
    }
  };

  const handleEdit = (vendor) => {
    setForm({ id: vendor.vendorid, vendorname: vendor.vendorname, contactinfo: vendor.contactinfo });
    setIsEditing(true);
  };

  const handleDelete = async (id) => {
    try {
      await deleteVendor(id);
      const vendors = await getVendors();
      setVendors(vendors);
    } catch (error) {
      console.error('Error deleting vendor:', error);
    }
  };

  return (
    <div className="table-container">
      <h1>Vendor Table</h1>
      <form onSubmit={handleSubmit} className="form-container">
        <input
          type="text"
          name="vendorname"
          value={form.vendorname}
          onChange={handleInputChange}
          placeholder="Vendor Name"
          required
        />
        <input
          type="text"
          name="contactinfo"
          value={form.contactinfo}
          onChange={handleInputChange}
          placeholder="Contact Info"
          required
        />
        <button type="submit">{isEditing ? 'Update' : 'Add'} Vendor</button>
      </form>
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Contact Info</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {vendors.map((vendor) => (
            <tr key={vendor.vendorid}>
              <td>{vendor.vendorname}</td>
              <td>{vendor.contactinfo}</td>
              <td>
                <button onClick={() => handleEdit(vendor)}>Edit</button>
                <button onClick={() => handleDelete(vendor.vendorid)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default VendorTable;