// services/openaiService.js
import OpenAI from "openai";

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  organization: process.env.ORG_ID,
  project: process.env.PROJECT_ID, dangerouslyAllowBrowser: true 
});

export const generateDescription = async (productName, vendorName) => {
  const prompt = vendorName
    ? `Generate a description for a product named "${productName}" from the vendor "${vendorName}".`
    : `Generate a description for a product named "${productName}".`;
  console.log("Prompt:", prompt);
  try {
    const response = await openai.chat.completions.create({
      model: "gpt-4o-mini",
      max_tokens: 20, // Adjust this value to limit the response length
      messages: [
        { role: "system", content: "You are a helpful assistant." },
        { role: "user", content: prompt },
      ],
    });
    console.log("API Response:", response);
    return response.choices[0].message.content.trim();
  } catch (error) {
    console.error("Error generating description:", error);
    return "";
  }
};