import React, { useState, useEffect } from 'react';
import { getProducts, addProduct, updateProduct, deleteProduct } from '../services/productService';
import { getVendors } from '../services/vendorService';
import { generateDescription } from '../services/openaiService';

import '../assets/styles/Table.css'; // Import the CSS file for styling

const ProductTable = () => {
  const [products, setProducts] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [form, setForm] = useState({ id: null, productName: '', vendorId: '', description: '' });
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const fetchProductsAndVendors = async () => {
      try {
        const [products, vendors] = await Promise.all([getProducts(), getVendors()]);
        setProducts(products);
        setVendors(vendors);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchProductsAndVendors();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleGenerateDescription = async () => {
    console.log("Generate Description button clicked");
    const vendorName = vendors.find(vendor => vendor.vendorid === form.vendorId)?.vendorname;
    console.log("Vendor Name:", vendorName);
    if (form.productName) {
      console.log("Generating description for:", form.productName, vendorName);
      const description = await generateDescription(form.productName, vendorName);
      console.log("Generated Description:", description);
      setForm({ ...form, description });
    } else {
      console.log("Product name is missing");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { productName, vendorId, description } = form;
  
    // Validate form data
    if (!productName || !vendorId || !description) {
      alert('Please fill in all required fields.');
      return;
    }
  
    try {
      if (isEditing) {
        await updateProduct(form.id, { productName, vendorId, description });
      } else {
        await addProduct({ productName, vendorId, description });
      }
      const products = await getProducts();
      setProducts(products);
      setForm({ id: null, productName: '', vendorId: '', description: '' });
      setIsEditing(false);
    } catch (error) {
      console.error('Error saving product:', error);
    }
  };

  const handleEdit = (product) => {
    setForm(product);
    setIsEditing(true);
  };

  const handleDelete = async (id) => {
    try {
      await deleteProduct(id);
      const products = await getProducts();
      setProducts(products);
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  return (
    <div className="table-container">
      <h1>Product Table</h1>
      <form onSubmit={handleSubmit} className="form-container">
        <input
          type="text"
          name="productName"
          value={form.productName}
          onChange={handleInputChange}
          placeholder="Product Name"
          required
        />
        <select
          name="vendorId"
          value={form.vendorId}
          onChange={handleInputChange}
          required
        >
          <option value="">Select Vendor</option>
          {vendors.map((vendor) => (
            <option key={vendor.vendorid} value={vendor.vendorid}>
              {vendor.vendorname}
            </option>
          ))}
        </select>
        <input
          type="text"
          name="description"
          value={form.description}
          onChange={handleInputChange}
          placeholder="Description"
          required
        />
        <button type="button" onClick={handleGenerateDescription}>Generate Description</button>
        <button type="submit">{isEditing ? 'Update' : 'Add'} Product</button>
      </form>
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Vendor</th>
            <th>Description</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <tr key={product.productid}>
              <td>{product.productname}</td>
              <td>{vendors.find((vendor) => vendor.vendorid === product.vendorid)?.vendorname}</td>
              <td>{product.description}</td>
              <td>
                <button onClick={() => handleEdit(product)}>Edit</button>
                <button onClick={() => handleDelete(product.productid)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProductTable;