import React from 'react';
import MainArea from './MainArea';
import '../assets/styles/HomePage.css';

const HomePage = () => {
    return (
        <div className="home-page">
            <MainArea />
        </div>
    );
};

export default HomePage;