import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faHome, faInfoCircle, faStore, faBoxOpen } from '@fortawesome/free-solid-svg-icons';
import '../assets/styles/Menu.css'; // Import the CSS file for styling

const Menu = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleMenu = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className={`menu ${isCollapsed ? 'collapsed' : ''}`}>
      <button onClick={toggleMenu} className="collapse-button">
        <FontAwesomeIcon icon={isCollapsed ? faBars : faTimes} />
      </button>
      <ul>
        <li>
          <Link to="/">
            <FontAwesomeIcon icon={faHome} />
            {!isCollapsed && <span className="menu-text"> Home</span>}
          </Link>
        </li>
        <li>
          <Link to="/managementPage">
            <FontAwesomeIcon icon={faInfoCircle} />
            {!isCollapsed && <span className="menu-text"> Management Page</span>}
          </Link>
        </li>
        <li>
          <Link to="/vendors">
            <FontAwesomeIcon icon={faStore} />
            {!isCollapsed && <span className="menu-text"> Vendors</span>}
          </Link>
        </li>
        <li>
          <Link to="/products">
            <FontAwesomeIcon icon={faBoxOpen} />
            {!isCollapsed && <span className="menu-text"> Products</span>}
          </Link>
        </li>
        <li>
          <Link to="/product-vendor">
            <FontAwesomeIcon icon={faBoxOpen} />
            {!isCollapsed && <span className="menu-text"> Products & Vendors</span>}
          </Link>
        </li>
        {/* Add more menu items as needed */}
      </ul>
    </div>
  );
};

export default Menu;