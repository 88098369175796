import React, { useState, useEffect } from 'react';
import { getVendors, addVendor } from '../services/vendorService';
import { getCategories, addCategory } from '../services/categoryService';
import { getProducts, addProduct } from '../services/productService';

const ManagementPage = () => {
  const [vendors, setVendors] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);

  const [vendorName, setVendorName] = useState('');
  const [contactInfo, setContactInfo] = useState('');

  const [categoryName, setCategoryName] = useState('');

  const [productName, setProductName] = useState('');
  const [vendorId, setVendorId] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');

  useEffect(() => {
    fetchVendors();
    fetchCategories();
    fetchProducts();
  }, []);

  const fetchVendors = async () => {
    const data = await getVendors();
    setVendors(data);
  };

  const fetchCategories = async () => {
    const data = await getCategories();
    setCategories(data);
  };

  const fetchProducts = async () => {
    const data = await getProducts();
    setProducts(data);
  };

  const handleAddVendor = async (e) => {
    e.preventDefault();
    await addVendor({ vendorName, contactInfo });
    fetchVendors();
    setVendorName('');
    setContactInfo('');
  };

  const handleAddCategory = async (e) => {
    e.preventDefault();
    await addCategory({ categoryName });
    fetchCategories();
    setCategoryName('');
  };

  const handleAddProduct = async (e) => {
    e.preventDefault();
    await addProduct({ productName, vendorId, description, price });
    fetchProducts();
    setProductName('');
    setVendorId('');
    setDescription('');
    setPrice('');
  };

  return (
    <div>
      <h1>Management Page</h1>

      <section>
        <h2>Add Vendor</h2>
        <form onSubmit={handleAddVendor}>
          <input
            type="text"
            placeholder="Vendor Name"
            value={vendorName}
            onChange={(e) => setVendorName(e.target.value)}
          />
          <input
            type="text"
            placeholder="Contact Info"
            value={contactInfo}
            onChange={(e) => setContactInfo(e.target.value)}
          />
          <button type="submit">Add Vendor</button>
        </form>
      </section>

      <section>
        <h2>Add Category</h2>
        <form onSubmit={handleAddCategory}>
          <input
            type="text"
            placeholder="Category Name"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
          />
          <button type="submit">Add Category</button>
        </form>
      </section>

      <section>
        <h2>Add Product</h2>
        <form onSubmit={handleAddProduct}>
          <input
            type="text"
            placeholder="Product Name"
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
          />
          <select
            value={vendorId}
            onChange={(e) => setVendorId(e.target.value)}
          >
            <option value="">Select Vendor</option>
            {vendors.map((vendor) => (
              <option key={vendor.vendorid} value={vendor.vendorid}>
                {vendor.vendorname}
              </option>
            ))}
          </select>
          <input
            type="text"
            placeholder="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <input
            type="number"
            placeholder="Price"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
          <button type="submit">Add Product</button>
        </form>
      </section>

      <section>
        <h2>Existing Vendors</h2>
        <ul>
          {vendors.map((vendor) => (
            <li key={vendor.vendorid}>{vendor.vendorname}</li>
          ))}
        </ul>
      </section>

      <section>
        <h2>Existing Categories</h2>
        <ul>
          {categories.map((category) => (
            <li key={category.categoryid}>{category.categoryname}</li>
          ))}
        </ul>
      </section>

      <section>
        <h2>Existing Products</h2>
        <ul>
          {products.map((product) => (
            <li key={product.productid}>{product.productname}</li>
          ))}
        </ul>
      </section>
    </div>
  );
};

export default ManagementPage;